a {
    text-decoration: none;
    color: inherit;
}

html {
    height: 100%;
    width: 100vw;
}

body {
    overflow-x: hidden;
    padding-right: 0 !important;
}

header {
    padding-right: 0 !important;
}

p {
    margin: 0;
    padding: 0;
}

* {
    font-family: 'PT Sans', sans-serif;
    -webkit-font-smoothing: initial

}

@font-face {
    font-family: "Circe";
    src: url("../fonts/Circe-Bold.eot");
    src: local("Circe Bold"), local("Circe-Bold"),
    url("../fonts/Circe-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Circe-Bold.woff") format("woff"),
    url("../fonts/Circe-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Circe";
    src: url("../fonts/Circe-ExtraBold.eot");
    src: local("Circe ExtraBold"), local("Circe-ExtraBold"),
    url("../fonts/Circe-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Circe-ExtraBold.woff") format("woff"),
    url("../fonts/Circe-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-webkit-autofill::first-line {
    font-size: 16px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-text-fill-color: inherit !important;
    transition: background-color 5000s ease-in-out 0s;
}

/*@keyframes autofill {*/
/*    100% {*/
/*        background: transparent !important;*/
/*        color: inherit !important;*/
/*        font-size: inherit !important;*/
/*    }*/
/*}*/

/*@-webkit-keyframes autofill {*/
/*    100% {*/
/*        background: transparent !important;*/
/*        color: inherit !important;*/
/*        font-size: inherit !important;*/
/*    }*/
/*}*/
